import { Container } from 'darrius';

import { HeadContent, Loading } from '../../components';

import styles from './FaqChatbotTemplate.module.scss';
import Script from 'next/script';
import { useEffect, useState } from 'react';

interface IFaqChatbotProps {
  meta: {
    title: string;
    description: string;
  };
  chatbot: {
    baseUrl: string;
    url: string;
    scriptUrl: string;
    id: string;
    type: 'Profissional' | 'Cliente';
  };
}

export const FaqChatbotTemplate = (props: IFaqChatbotProps) => {
  const { meta, chatbot } = props;

  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const observer = new MutationObserver((_, o) => {
      const element: HTMLElement = document.querySelector(
        '.embeddedMessagingConversationButton'
      );
      if (element) {
        o.disconnect();
        setShowLoading(false);
        element.click();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  }, []);

  const handleChatLoad = () => {
    try {
      // @ts-expect-error
      embeddedservice_bootstrap.settings.language = 'pt_BR';
      //@ts-expect-error
      embeddedservice_bootstrap.init(chatbot.id, chatbot.type, chatbot.url, {
        scrt2URL: chatbot.baseUrl,
      });
    } catch (err) {
      console.error('Error loading Embedded Messaging: ', err);
    }
  };

  return (
    <>
      <HeadContent
        metaTitle={meta.title}
        metaDescription={meta.description}
        pageType="faq_chatbot:show"
        noindex
      />
      <Container>
        {showLoading && (
          <div className={styles.chatbot__wrapper}>
            <Loading />
          </div>
        )}
        <Script
          type="text/javascript"
          src={chatbot.scriptUrl}
          onLoad={handleChatLoad}
        />
      </Container>
    </>
  );
};
