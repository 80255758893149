import { type AxiosRequestConfig } from 'axios';

import { axiosInstance } from '../utils/axiosInstance';
import { applyCustomHeaders } from '../utils/server/applyCustomHeaders';
import { handleErrorResponse } from '../error/handleErrorResponse';

const ERROR_SERVICE_NAME_PREFIX = '[SCORPION-SERVICE]';

enum EACCEPT {
  DEFAULT = '*/*',
  'HAL+JSON' = 'application/hal+json',
}

type TAcceptParam = keyof typeof EACCEPT;

interface IScorpionService {
  path: string;
  accept?: TAcceptParam;
  customHeaders?: Record<string, string>;
  useCache?: boolean;
  method?: 'GET' | 'POST';
  body?: unknown;
}

const scorpionService = async <T = unknown>({
  accept = 'DEFAULT',
  customHeaders = {},
  path,
  useCache = true,
  method = 'GET',
  body,
}: IScorpionService): Promise<T> => {
  const {
    SCORPION_ENDPOINT_URL,
    SCORPION_API_KEY,
    FRONTEND_REQUEST_LAYER_URL,
  } = process.env;

  const headers = {
    'Accept-Language': 'pt-BR',
    Accept: EACCEPT[accept],
    'GN-APIKEY': SCORPION_API_KEY,
  };

  applyCustomHeaders({
    headers,
    customHeaders,
    keys: ['x-request-id', 'Authorization'],
  });

  let url = `${FRONTEND_REQUEST_LAYER_URL}/api/v2/proxy/core?path=${path}`;

  if (!useCache) {
    url = `${SCORPION_ENDPOINT_URL}/${path}`;
  }

  const requestOptions: AxiosRequestConfig = {
    headers,
    url,
    method,
    ...(body && { data: body }),
  };

  try {
    const { data } = await axiosInstance<T>(requestOptions);

    return data;
  } catch (error) {
    throw handleErrorResponse({
      error,
      prefix: ERROR_SERVICE_NAME_PREFIX,
    });
  }
};

export { scorpionService };