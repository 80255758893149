import Image from 'next/image';
import styles from './BannerEcommerce.module.scss';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { axiosInstance } from '../../../utils/axiosInstance';
import { imgPrefix } from '../../../utils';

interface BannerData {
  src: string;
  link: string;
}

export default function BannerEcommerce({
  categoryId,
  categoryName,
  customClassName,
}: {
  categoryId: number;
  categoryName: string;
  customClassName: string;
}) {
  const [src, setSrc] = useState('');
  const [link, setLink] = useState('');
  const [bannerName, setBannerName] = useState('');

  useEffect(() => {
    async function getBannerAttributes() {
      try {
        const requestOptions = {
          url: `${process.env.NEXT_PUBLIC_API_CLIENTS}/v1/category/banner?id=${categoryId}`,
          method: 'GET',
        };

        const { data } = await axiosInstance<BannerData>(requestOptions);

        if (data?.src) {
          setSrc(data.src);
          setBannerName(
            data.src
              .split('/')
              .pop()
              .replace(/\.[^/.]+$/, '')
          );
        }
        if (data?.link) {
          setLink(data.link);
        }
      } catch (error) {}
    }
    getBannerAttributes();
  }, []);

  return src && link ? (
    <div className={`${styles['banner-root']} ${styles[customClassName]}`}>
      <Link
        href={`${link}?utm_source=getninjas_site&utm_medium=banner&utm_campaign=${categoryName}&utm_banner=${bannerName}`}
        target="_blank"
      >
        <Image
          src={imgPrefix(`/images/${src}`)}
          alt={'Banner do e-commerce da Europ'}
          width={350}
          height={306}
        />
      </Link>
    </div>
  ) : undefined;
}
