import { Icon as DarriusIcon } from 'darrius';

import {
  type AlertRootProps,
  type AlertProps,
  type AlertIconProps,
} from './@interfaces';

import styles from './Alert.module.scss';

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
const Root = ({ children, onClick, direction }: AlertRootProps) => (
  <div
    className={`${styles.root} ${styles[`root--${direction || 'row'}`]}`}
    onClick={onClick}
  >
    {children}
  </div>
);
/* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

const Icon = ({ image, description }: AlertIconProps) => (
  <DarriusIcon src={image} alt={description} size={36} />
);

const Link = ({ image, description }: AlertIconProps) => (
  <DarriusIcon
    className={styles.link}
    src={image}
    alt={description}
    size={24}
  />
);

const Body = ({ children, className }: AlertProps) => (
  <div className={className || ''}>{children}</div>
);

const Header = ({children}: AlertProps) => (
  <div className={`${styles.header}`}>
    {children}
  </div>
)

const Title = ({ children }: AlertProps) => (
  <p className={styles.title}>{children}</p>
);

const Text = ({ children }: AlertProps) => (
  <p className={styles.text}>{children}</p>
);

const Strong = ({children}: AlertProps) => (
  <strong className={styles['alert-text-strong']}>{children}</strong>
)

export const Alert = { Root, Icon, Link, Body, Header, Title, Text, Strong };
