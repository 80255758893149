import { Suspense, useState } from 'react';
import dynamic from 'next/dynamic';

import { Button, Col, Icon, Row } from 'darrius';

import { Dialog, DialogLoading } from '../../../../components';

import styles from './FaqChatCta.module.scss';
import { useRouter } from 'next/router';
import { type TFaqChat } from '../../../../locales/@interfaces/faq';
import { imgPrefix } from '../../../../utils';

interface IFaqChatCtaProps {
  chat: TFaqChat;
}

const FaqChatDialogTemplate = dynamic(
  () => import('../FaqChatDialogTemplate/FaqChatDialogTemplate'),
  {
    loading: () => <DialogLoading />,
    ssr: false,
  }
);

export function FaqChatCta(props: IFaqChatCtaProps) {
  const { chat } = props;
  const router = useRouter();
  const [isFaqChatDialogOpen, setIsFaqChatDialogOpen] = useState(false);

  function handleOpenFaqChatDialog() {
    setIsFaqChatDialogOpen((prevState) => !prevState);
  }

  function handleOpenFaqChat() {
    router.push('/pedir-ajuda');
  }

  return (
    <Dialog.Root
      onOpenChange={handleOpenFaqChatDialog}
      open={isFaqChatDialogOpen}
    >
      <section className={styles.chat}>
        <Row className={styles.chat__text}>
          <Col className="col-sm-12">
            <span>{chat.title}</span>
          </Col>
        </Row>

        <Row>
          <Col className="col-sm-12">
            <Dialog.Trigger asChild>
              <Button.Root
                className={styles.chat__botton}
                variation="secondary"
                size="small"
              >
                <Button.Icon name="MdChat" />
                {chat.button.text}
              </Button.Root>
            </Dialog.Trigger>
          </Col>
        </Row>
        <Row>
          <Col className="col-sm-12">
            <Dialog.Trigger asChild>
              <Button.Root
                className={styles.chat__botton}
                variation="secondary"
                size="small"
                onClick={() => {
                  handleOpenFaqChat();
                  setIsFaqChatDialogOpen((prevState) => !prevState);
                }}
              >
                <Icon
                  className={styles.chat__botton__icon}
                  alt=""
                  src={imgPrefix('/images/pedir-ajuda/pedir-ajuda-icon.svg')}
                />
                {chat.formButton.text}
              </Button.Root>
            </Dialog.Trigger>
          </Col>
        </Row>
      </section>

      {isFaqChatDialogOpen && (
        <Suspense fallback={<DialogLoading />}>
          <FaqChatDialogTemplate chat={chat} />
        </Suspense>
      )}
    </Dialog.Root>
  );
}
